<template>
	<div class="dashboard">
		<Breadcrumbs :items="crumbs" />
    <div class="dashboard__main">
      <div class="dashboard__main--header">
        <h1 class="ml-3 mt-3" v-if="customer">{{customer.entity}}</h1>
        <router-link :to="{ name: 'dashCustomers' }">
          <button class="btn"><i class="fad fa-arrow-left fa-2x"></i></button>
        </router-link>
      </div>
      <hr>
      <div class="flex flex-wrap">
        <div class="flex__col1">
          <transition name="fadeStop">
            <Loader v-if="!customer" />
          </transition>
          <div v-if="customer">
        	 <div class="card mb-3" v-if="customer">
            <h2>{{customer.entity}}</h2>
            <p class="mb-1">Primary Contact: {{customer.firstName}} {{customer.lastName}}</p>
            <p class="mb-1">Primary Contact Phone: {{customer.phone}}</p>
            <p class="mb-1">Primary Contact Email: {{customer.email}}</p>
            <p v-if="customer.anythingElse">Anything else we should know? {{customer.anythingElse}}</p>
          </div>
          </div>
        </div>
        <div class="flex__col2">
        	
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'dashCustomer',
  components: {
    Loader,
    Breadcrumbs
  },
  created () {
    this.$store.dispatch("getCustomerFromId", this.$route.params.id);
  },
  computed: {
    ...mapState(['currentUser', 'customerProfile', 'customer']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Dashboard", to: { name: "dashHome"}}
      let step2 = { title: "Customers", to: { name: "dashCustomers"}}
      let step3 = { title: "Customer", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      crumbsArray.push(step3)
      return crumbsArray
    },
  },
  beforeDestroy(){
    this.$store.dispatch('clearCustomer')
  }
}
</script>